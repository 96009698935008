import {Button, Input, notification, Form, Spin, Result, Checkbox} from "antd";
import Title from "antd/lib/typography/Title";
import {useForm} from "antd/es/form/Form";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {ApprovalDiff, Quote, QuoteReview} from "../../api/models";
import {ConfiguratorContext } from "../../context";
import {useAsyncState} from "../../hook/useAsyncState";
import BMButton, {BMButtonProps} from "../BMButton";
import ApprovalDiffTable from "../Table/ApprovalDiffTable";
import ModalWizard from "../ModalWizard";
import Utils from "../../util/util";
import QuoteReviewDetail from "../QuoteReviewDetail";
import _ from "lodash";
import { useQuoteContext } from "../../contexts/QuoteContext";

const EMPTY_DIFF = {
    "assembliesDiff": {
        "removedAssemblies": [],
        "addedAssemblies": []
    }
};

const SubmitEngineeringChangeButton = (props:Omit<BMButtonProps, 'onChange'> & {
  onChange?: (q:Quote) => void,
}) => {

  const { onChange:a, ...btnProps } = props;

  const {quoteAsync, adminView} = useQuoteContext();
  const quote = quoteAsync?.val;
  const [review, reviewAsync] = useAsyncState<QuoteReview>();
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [diff, diffAsync] = useAsyncState<ApprovalDiff>();
  const [form] = useForm();
  const changeSummary = Form.useWatch<string>('changeSummary', form);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const submitChangeOrder = async () : Promise<Quote|undefined> => {
    if ( !quote ) return;

    const forceErpRevision = form.getFieldValue('forceErpRevision');
    try {

      quoteAsync.setLoading();
      const resp = await configurator.api.submitEngineeringChange(quote.displayRevisionId, changeSummary, forceErpRevision );
      quoteAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      const msg = "Failed to create change order. " + errorMsg;

      notification.error( { message: msg });
      quoteAsync.setFail(e.message);
    }

    return;
  };

  const loadReview = async (quoteRevisionId:number | undefined) : Promise<QuoteReview | undefined> => {
    if ( !quoteRevisionId ) return;

    reviewAsync.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteReview(quoteRevisionId, {dealerView: !adminView} );
      reviewAsync.setDone(resp.data);
      return resp.data;
    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch review. " + errorMsg });
      reviewAsync.setFail(e.message);
    }
    return;
  }

  const handleConfirmSubmit = async () => {

    await Promise.all([
          loadDiff(),
    loadReview(quote?.displayRevisionId)
    ]);

    setIsOpen( true );
  }


  const handleSubmit = async () => {
    setDisableSubmit(true);
    const quote = await submitChangeOrder();
    if ( quote ) {
      props.onChange?.(quote);

      setIsOpen(false);
    }

  }


  const loadDiff = async () : Promise<ApprovalDiff | undefined> => {
    if ( !quote ) return;

    try{
      diffAsync.setLoading();
      const resp = await configurator.api.verifyRevisionChange(
        {
          quoteId: quote.id,
          verifyingRevisionId: quote.displayRevisionId,
          compareCurrentRevision: true,
        }
      );
      diffAsync.setDone(resp.data.diffDto);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response.data.message });
      const msg = "Failed to get change difference. " + errorMsg;

        notification.error( { message: msg });
        diffAsync?.setFail(e.message);
    }     
  }

  const hasChanges = !_.isEqual(diff, EMPTY_DIFF);

  const handleCancel = () => {
    setDisableSubmit(false);
    setIsOpen(false)
  }

  return <>
    <BMButton
      type="primary"
      onClick={handleConfirmSubmit}
      {...btnProps}
      loading={props.loading || quoteAsync?.isLoading() || reviewAsync.isLoading()}
    >
      Submit
    </BMButton>
    <ModalWizard
      title={'Engineering Change Submission'}
      showSteps={false}
      centered
      open={isOpen}
      onCancel={handleCancel}
      width={'50rem'}
      steps={[
        {
            key:1,
          body: (nav) => <Result status="warning" 
            title={"The following issues with the order have been identified."}
            subTitle={<Title level={5}>Continue to submit for approval?</Title> }
          >
            <QuoteReviewDetail 
              review={review} 
              ignoreDashDrawing={true} 
            />
          </Result>,
          footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
            <Button key="next" type="primary" onClick={nav.nextStep} >Next</Button>
            <Button key="back" onClick={() => setIsOpen(false)} >Cancel</Button>
          </div>,
          hidden: !Utils.reviewHasErrors(review)
        },
        {
            key:2,
          body: (nav) => 
            <Spin spinning={diffAsync.isLoading() || quoteAsync?.isLoading()}>
              <ApprovalDiffTable diff={diff} />
              <Form
                form={form}
              >
                <Form.Item
                  name="changeSummary"
                  label="Change Summary"
                  rules={[{ required: true, message: "Change summary is required" }]}
                >
                  <Input placeholder={'Please enter the change summary here.'} />
                </Form.Item>
                <Form.Item
                  name="forceErpRevision"
                  label="Force Epicor Revision"
                  valuePropName="checked"
                  hidden={hasChanges}
                >
                  <Checkbox />
                </Form.Item>
              </Form>
            </Spin>,
            footer: (nav) => <div style={{display: "flex", gap: ".5rem", flexDirection: "row-reverse", padding: "1rem .3rem .3rem .3rem" }}>
              <Button key="next" type="primary" disabled={!changeSummary || disableSubmit} onClick={handleSubmit} >Submit</Button>
              <Button key="back" onClick={nav.prevStep} >Back</Button>
            </div>,
        }
      ]}    
    />

  </>
}

export default SubmitEngineeringChangeButton;
